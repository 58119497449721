import CreateMarketForm from "../components/createMarketForm";

import Header from "../components/header";
import "./createMarket.css";

function CreateMarketPage() {
    return (
        <div>
            <Header />
            <div className="content">
                <CreateMarketForm />
            </div>
        </div>
    );
}

export default CreateMarketPage;
