import { useState } from "react";

import { updateMarket } from "../api/marketApi";
import { Market } from "../models/Market";

export default function EditMarketForm(props: any) {
    const { market } = props;
    const [editedMarket, setEditedMarket] = useState<Market>(market);

    const addHours = () => {
        const updatedMarket = {
            ...editedMarket,
            business_hours: [
                ...editedMarket.business_hours,
                { day_of_week: "Saturday", open_time: "", close_time: "" },
            ],
        };
        setEditedMarket(updatedMarket);
    };

    const handleHoursChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const updatedMarket = { ...editedMarket };
        updatedMarket.business_hours[index] = {
            ...editedMarket.business_hours[index],
            [name]: value,
        };
        console.log(updatedMarket);
        setEditedMarket(updatedMarket);
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        updateMarket(editedMarket)
            .then((response) => {
                console.log("Market updated: ", response);
                alert("Market updated successfully!");
            })
            .catch((error) => {
                console.error("Error creating market: ", error);
                alert("Error creating market: " + error);
            });
    };

    return (
        <form className="marketForm" onSubmit={onSubmit}>
            <label>Name</label>
            <input
                type="text"
                name="marketName"
                value={editedMarket.name}
                onChange={(e) => {
                    const updatedMarket = { ...editedMarket, name: e.target.value };
                    setEditedMarket(updatedMarket);
                }}
            />
            <label>Address</label>
            <input
                type="text"
                name="address"
                value={editedMarket.address.street_address}
                onChange={(e) => {
                    const updatedMarket = {
                        ...editedMarket,
                        address: { ...editedMarket.address, street_address: e.target.value },
                    };
                    setEditedMarket(updatedMarket);
                }}
            />
            <input
                type="text"
                name="locality"
                value={editedMarket.address.locality}
                onChange={(e) => {
                    const updatedMarket = {
                        ...editedMarket,
                        address: { ...editedMarket.address, locality: e.target.value },
                    };
                    setEditedMarket(updatedMarket);
                }}
            />
            <input
                type="text"
                name="state"
                value={editedMarket.address.state}
                onChange={(e) => {
                    const updatedMarket = {
                        ...editedMarket,
                        address: { ...editedMarket.address, state: e.target.value },
                    };
                    setEditedMarket(updatedMarket);
                }}
            />
            <input
                type="text"
                name="postalCode"
                value={editedMarket.address.postal_code}
                onChange={(e) => {
                    const updatedMarket = {
                        ...editedMarket,
                        address: { ...editedMarket.address, postal_code: e.target.value },
                    };
                    setEditedMarket(updatedMarket);
                }}
            />
            <div>
                <label>Business Hours:</label>
                {editedMarket.business_hours.map((hours, index) => (
                    <div key={index}>
                        <select
                            name="day_of_week"
                            value={hours.day_of_week}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        >
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                        </select>
                        <label> from </label>
                        <input
                            type="time"
                            name="open_time"
                            value={hours.open_time}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        />
                        <label> to </label>
                        <input
                            type="time"
                            name="close_time"
                            value={hours.close_time}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        />
                    </div>
                ))}
                <button type="button" onClick={addHours}>
                    Add day
                </button>
            </div>
            <button type="submit">Edit market</button>
        </form>
    );
}
