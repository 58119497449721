import { getDistance } from "geolib";
import type { GeolibInputCoordinates } from "geolib/es/types";

import type { Coordinates, CreateMarketInput, Market } from "../models/Market";

const PROD_BASE_URL = "https://ug87k99fu7.execute-api.us-east-1.amazonaws.com/prod";
const BASE_URL = process.env.NODE_ENV === "production" ? PROD_BASE_URL : "http://localhost:3000";
const METERS_TO_KILOMETERS = 1000;

export async function getMarkets(
    coordinates: Coordinates,
    bounds: GeolibInputCoordinates[],
    limit: number = 50
): Promise<Market[]> {
    const url = new URL(BASE_URL + "/markets");
    url.searchParams.append("limit", limit.toString());
    url.searchParams.append("latitude", coordinates.latitude.toString());
    url.searchParams.append("longitude", coordinates.longitude.toString());
    if (bounds.length > 0) {
        let radiusKilometers = Math.ceil(
            getDistance(bounds[0], bounds[1]) / (2 * METERS_TO_KILOMETERS)
        );
        url.searchParams.append("distance", Math.round(radiusKilometers).toString());
        url.searchParams.append("distanceUnits", "kilometers");
    }

    console.log("Fetching markets from ", url.toString());
    try {
        const response = await fetch(url.toString());
        const marketsResponse = await response.json();
        return marketsResponse.markets;
    } catch (error) {
        console.error("Error fetching markets: ", error);
        return [];
    }
}

export async function getMarket(marketId: number): Promise<Market> {
    const url = new URL(BASE_URL + "/markets/");
    url.searchParams.append("id", marketId.toString());

    console.log("Fetching market from ", url.toString());
    try {
        const response = await fetch(url.toString());
        return await response.json();
    } catch (error) {
        console.error("Error fetching market: ", error);
        return Promise.reject(error);
    }
}

export async function createMarket(market: CreateMarketInput): Promise<Market> {
    console.log("Creating market: ", market);
    const url = new URL(BASE_URL + "/markets");
    try {
        const response = await fetch(url.toString(), {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(market),
        });
        if (!response.ok) {
            const message = await response.text();
            return Promise.reject(message);
        }
        return await response.json();
    } catch (error) {
        console.error("Error while making creating market request: ", error);
        return Promise.reject(error);
    }
}

export async function updateMarket(market: Market): Promise<Market> {
    console.log("Updating market: ", market);
    const url = new URL(BASE_URL + "/markets/" + market.id);
    try {
        const response = await fetch(url.toString(), {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(market),
        });
        if (!response.ok) {
            const message = await response.text();
            return Promise.reject(message);
        }
        return await response.json();
    } catch (error) {
        console.error("Error while making update market request: ", error);
        return Promise.reject(error);
    }
}
