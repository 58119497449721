import { Route, Routes } from "react-router-dom";

import CreateMarketPage from "./pages/createMarket";
import EditMarketPage from "./pages/editMarket";
import HomePage from "./pages/home";

import "./App.css";
import AboutPage from "./pages/about";

function App() {
    return (
        <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/createMarket" element={<CreateMarketPage />} />
            <Route path="/editMarket">
                <Route path=":id" element={<EditMarketPage />} />
            </Route>
        </Routes>
    );
}

export default App;
