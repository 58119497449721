import Header from "../components/header";
import SubscribeForm from "../components/subscribeForm";

import "./about.css";

export default function AboutPage() {
    return (
        <div>
            <Header />
            <div className="about">
                <h2>About FreshFinder</h2>
                <p>
                    Hi! I'm Julien, the person behind FreshFinder. I started working on FreshFinder
                    in 2024, but the idea for FreshFinder took root years ago when I started
                    learning more about our food system and realized how little I know about most of
                    the food I buy and eat.
                </p>
                <p>
                    My goal is to make FreshFinder the best resource for anyone interested in food.
                    Whether you're a home cook searching for inspiration, a busy parent planning a
                    healthy diet for your family, or a professional chef looking for the freshest
                    ingredients, I hope you find what you're looking for here!
                </p>
                <p>
                    Questions, suggestions, or feedback? Please contact me at{" "}
                    <b>hello@freshfinderapp.com</b>.
                </p>
                <p>
                    Interested in receiving updates about FreshFinder? Subscribe to the newsletter
                    below!
                </p>
                <SubscribeForm />
            </div>
        </div>
    );
}
