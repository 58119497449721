import { useEffect, useState } from "react";
import { MapProvider } from "react-map-gl";

import { getMarkets } from "../api/marketApi";
import Header from "../components/header";
import MarketMap from "../components/map";
import MarketsList from "../components/marketsList";
import ReloadMarketsButton from "../components/reloadMarketsButton";
import { Market } from "../models/Market";

import "./home.css";

function HomePage() {
    const initialViewState = { latitude: 40.71, longitude: -73.97, zoom: 12 };
    const [viewState, setViewState] = useState(initialViewState);
    const [markets, setMarkets] = useState<Market[]>([]);
    const [focusedMarket, setFocusedMarket] = useState<Market | null>(null);

    async function loadMarkets() {
        const markets = await getMarkets(initialViewState, []);
        setMarkets(markets);
    }

    useEffect(() => {
        loadMarkets();
    }, []);

    return (
        <div>
            <Header />
            <div className="content">
                <MapProvider>
                    <MarketsList markets={markets} setFocusedMarket={setFocusedMarket} />
                    <div className="map-container">
                        <MarketMap
                            markets={markets}
                            viewState={viewState}
                            focusedMarket={focusedMarket}
                            setViewState={setViewState}
                            setMarkets={setMarkets}
                            setFocusedMarket={setFocusedMarket}
                        />
                        <ReloadMarketsButton setMarkets={setMarkets} />
                    </div>
                </MapProvider>
            </div>
        </div>
    );
}

export default HomePage;
