import { useState } from "react";

export default function SubscribeForm() {
    const [email, setEmail] = useState("");

    const handleChange = (e: any) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log(email);
        fetch("https://connect.mailerlite.com/api/subscribers", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiMTMzMjBiNmZjNWFkNWU3MTc2Yzk0Yzk5ZTAyNDA5ZGNmZWNjY2U2NWRkNmVlODA0NmU0OTYxNzIyZDk2MTUzNWU5NzA2MDU2NzJkMTRkYzkiLCJpYXQiOjE3MTUxODYyNDQuMzgyMDYyLCJuYmYiOjE3MTUxODYyNDQuMzgyMDY1LCJleHAiOjQ4NzA4NTk4NDQuMzc4Miwic3ViIjoiOTQ4NDMzIiwic2NvcGVzIjpbXX0.odooaSTkqNtnOAz-gvA8Bhon0ebJPQ6V8aMCQ2pNHmuesNFR3ceuYBCY01Y_TwszEIQ1L47I5fvueD2SjpuzEzuG0TiRWMT5JyRqR8slsN0QPvfMvAT9_ijdFFOLiqNQg3KhHLSjZAHGtyrUBvV28rVkKZIxIIhfh7L5O7y74wv8xgRm5yGP217ipQgWUgBTrEgo786Ka0xmV-60QNKsXdvCreVY3lrHwc0iAc2o4GHMk15tdo87Gzf_uCTcs4VUEVOMofKrBga35mM8rUzz2AJIqpJALXIa97O4NAa1TcPXIzgiItDeMHVT9viqamHwyC3SfPgihrg5XKMevnVS0eW6Etz6LV8lE5dcUQMkmCKdgKaXAwRcEVwF7Wshp_jwGG5SC9li3nSphmcACVh5hXOTh_dMTCxSrYq-5v8zWi1kc90POK2Kacq1ddk8c7Ru-aasL2xx36sqF3J1HBSLX_fuzpWrdi_oW00e7Xb-HAmUnX-K1OI34KZaszIXJDV9dhbomVC7-JAs-nRlgZVKYFQtHeQPNyGP_6R7oc0tUSoEu8x3ZCVvLWOUo5hlwx-9xGoP_GyoZ-ohqweEjm5Hp0Lr9_O-GvE2gmgOC_7wDDTdO_DrQO6dlbBM28fkv7K3GwTpwXtqDk9Icwaj3skU-s6RFovUbYPf3GsgsrUv-QY",
            },
            body: JSON.stringify({ email: email }),
        })
            .then((response) => response.json())
            .then((data) => console.log(data))
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    return (
        <form className="subscribe" onSubmit={handleSubmit}>
            <input
                type="email"
                id="email"
                value={email}
                onChange={handleChange}
                placeholder="Email"
            />
            <button type="submit">Subscribe for updates!</button>
        </form>
    );
}
