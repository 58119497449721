import type { GeolibInputCoordinates } from "geolib/es/types";
import { useMap } from "react-map-gl";

import { getMarkets } from "../api/marketApi";
import type { Market } from "../models/Market";

type reloadMarketButtonProps = {
    setMarkets: (markets: Market[]) => void;
};

export default function ReloadMarketsButton(props: reloadMarketButtonProps) {
    const { setMarkets } = props;
    const { marketMap } = useMap();

    async function refreshMarkets() {
        if (!marketMap) {
            return;
        }
        const center = marketMap.getMap().getCenter();
        const zoom = marketMap.getMap().getZoom();
        const viewState = { latitude: center.lat, longitude: center.lng, zoom: zoom };
        const bounds = marketMap.getMap().getBounds().toArray();
        const geobounds: GeolibInputCoordinates[] = [
            { latitude: bounds[0][1], longitude: bounds[0][0] },
            { latitude: bounds[1][1], longitude: bounds[1][0] },
        ];
        const markets = await getMarkets(viewState, geobounds);
        setMarkets(markets);
    }

    return (
        <button className="refreshButton" onClick={refreshMarkets}>
            <img src="refresh.png" alt="refresh" className="refreshIcon" />
        </button>
    );
}
