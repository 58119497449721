export default function Header() {
    return (
        <div className="header">
            <div className="logo">
                <a href="/">
                    <img src="/logo.png" alt="logo" width={"80px"} height={"80px"} />
                </a>
                <h1>FreshFinder</h1>
            </div>
            <div className="nav">
                <a href="/about">About</a>
            </div>
        </div>
    );
}
