import MapboxGeocoder, { GeocoderOptions } from "@mapbox/mapbox-gl-geocoder";
import type { GeolibInputCoordinates } from "geolib/es/types";
import { ControlPosition, useControl } from "react-map-gl";

import { getMarkets } from "../api/marketApi";
import type { Coordinates, Market } from "../models/Market";

import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";

type GeocoderSearchBarProps = Omit<GeocoderOptions, "accessToken" | "mapboxgl"> & {
    mapboxAccessToken: string;
    position: ControlPosition;
    setMarkets: (markets: Market[]) => void;
};

export default function GeocoderSearchBar(props: GeocoderSearchBarProps) {
    useControl<MapboxGeocoder>(
        () => {
            const ctrl = new MapboxGeocoder({
                ...props,
                accessToken: props.mapboxAccessToken,
                placeholder: props.placeholder || "Search for town or place",
                minLength: 3,
                types: "district,place,locality",
                countries: "us",
                flyTo: {
                    linear: true,
                },
            });
            ctrl.on("result", (evt) => {
                console.log("Geocoder result:", evt.result);
                if (evt.result.center && evt.result.bbox) {
                    const center: Coordinates = {
                        latitude: evt.result.center[1],
                        longitude: evt.result.center[0],
                    };
                    const bounds: GeolibInputCoordinates[] = [
                        { latitude: evt.result.bbox[1], longitude: evt.result.bbox[0] },
                        { latitude: evt.result.bbox[3], longitude: evt.result.bbox[2] },
                    ];
                    getMarkets(center, bounds)
                        .then((markets) => {
                            props.setMarkets(markets);
                        })
                        .catch((e) => console.error(e));
                }
            });
            ctrl.on("error", (e) => console.error(e));
            return ctrl;
        },
        {
            position: props.position,
        }
    );

    return null;
}
