import { DateTime } from "luxon";

export type Market = {
    id: number;
    name: string;
    description?: string;
    location: Coordinates;
    address: Address;
    business_hours: BusinessHours[];
};

type Address = {
    state: string;
    locality: string;
    street_address: string;
    postal_code: string;
};

export type Coordinates = {
    latitude: number;
    longitude: number;
};

type BusinessHours = {
    day_of_week: string;
    open_time: string;
    close_time: string;
    start_month?: string;
    end_month?: string;
    start_date?: Date;
    end_date?: Date;
};

export type CreateMarketInput = Partial<Market>;

export function convertTimeStringToLocal(timestring: string): string {
    return DateTime.fromISO(timestring).toLocaleString(DateTime.TIME_SIMPLE);
}

export type ViewState = Coordinates & {
    zoom: number;
};
