import { useMap } from "react-map-gl";

import type { Market } from "../models/Market";
import { convertTimeStringToLocal } from "../models/Market";

type marketsListProps = {
    markets: Market[];
    setFocusedMarket: (market: Market | null) => void;
};

export default function MarketsList(props: marketsListProps) {
    const numMarkets = props.markets.length;
    let message = "";
    if (numMarkets === 50) {
        message = "Showing 50 markets. Zoom in and refresh to show more.";
    } else if (numMarkets === 0) {
        message =
            "No markets found in this area. Please expand your search, or let us know about a missing market!";
    } else if (numMarkets === 1) {
        message = "One result";
    } else {
        message = numMarkets + " results";
    }

    const { marketMap } = useMap();
    function onClick(market: Market) {
        if (!marketMap) {
            return;
        }
        marketMap.flyTo({
            center: [market.location.longitude, market.location.latitude],
            zoom: 14,
        });
        props.setFocusedMarket(market);
    }

    return (
        <div className="sidebar">
            <div className="sidebar-heading">
                <h2>Markets</h2>
                <p>{message}</p>
            </div>
            <div id="listings" className="listings">
                {props.markets.map((market) => (
                    <div key={market.id} className="item" onClick={() => onClick(market)}>
                        <h3 className="listingName">{market.name}</h3>
                        <p>
                            {market.address.street_address}, {market.address.locality}
                        </p>
                        {market.business_hours.map((hours) => (
                            <p key={hours.day_of_week}>
                                {hours.day_of_week} {convertTimeStringToLocal(hours.open_time)} to{" "}
                                {convertTimeStringToLocal(hours.close_time)}
                            </p>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
