import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { getMarket } from "../api/marketApi";
import EditMarketForm from "../components/editMarketForm";
import Header from "../components/header";
import { Market } from "../models/Market";

export default function EditMarketPage() {
    const { id } = useParams();
    const [originalMarket, setMarket] = useState<Market>();

    useEffect(() => {
        const fetchMarket = async (id: number) => {
            const market = await getMarket(id);
            setMarket(market);
        };

        console.log(id);
        if (id) {
            fetchMarket(parseInt(id as string));
        }
    }, [id]);

    return (
        <div>
            <Header />
            <div className="content">
                {originalMarket && <EditMarketForm market={originalMarket} />}
            </div>
        </div>
    );
}
