import { useState } from "react";

import { AddressAutofill } from "@mapbox/search-js-react";

import { createMarket } from "../api/marketApi";
import type { Coordinates, CreateMarketInput } from "../models/Market";

export default function CreateMarketForm() {
    // State variables to store form values
    const [marketName, setMarketName] = useState("");
    const [address, setAddress] = useState("");
    const [locality, setLocality] = useState("");
    const [state, setState] = useState("");
    const [postalCode, setPostalCode] = useState("");
    const [businessHours, setBusinessHours] = useState([
        { day_of_week: "Saturday", open_time: "", close_time: "" },
    ]);
    const [coordinates, setCoordinates] = useState<Coordinates>();

    // Handle form submission
    const onSubmit = (e: any) => {
        e.preventDefault();
        const newMarket: CreateMarketInput = {
            name: marketName,
            location: coordinates,
            address: {
                state: state,
                locality: locality,
                street_address: address,
                postal_code: postalCode,
            },
            business_hours: [],
        };
        for (const hours of businessHours) {
            newMarket.business_hours?.push({
                day_of_week: hours.day_of_week,
                open_time: hours.open_time,
                close_time: hours.close_time,
            });
        }

        createMarket(newMarket)
            .then((response) => {
                console.log("Market created: ", response);
                alert("Market created successfully!");
            })
            .catch((error) => {
                console.error("Error creating market: ", error);
                alert("Error creating market: " + error);
            });
    };

    const mapboxAccessToken =
        "pk.eyJ1IjoianVsaWVua2IiLCJhIjoiY2xrb2s1bjIwMG1rdzNxcHJwMDJvaDUxOSJ9.4k21H-PHzAT7qKOKNWJLbA";

    const handleAutofillRetrieval = (e: any) => {
        const result = e.features[0];
        setCoordinates({
            longitude: result.geometry.coordinates[0],
            latitude: result.geometry.coordinates[1],
        });
    };

    const addHours = () => {
        setBusinessHours([
            ...businessHours,
            { day_of_week: "Saturday", open_time: "", close_time: "" },
        ]);
    };

    const handleHoursChange = (e: any, index: number) => {
        const { name, value } = e.target;
        const updatedHours = {
            ...businessHours[index],
            [name]: value,
        };
        const updateBusinessHours = [...businessHours];
        updateBusinessHours[index] = updatedHours;
        setBusinessHours(updateBusinessHours);
    };

    return (
        <form className="marketForm" onSubmit={onSubmit}>
            <input
                type="text"
                name="marketName"
                placeholder="Market Name"
                value={marketName}
                onChange={(e) => setMarketName(e.target.value)}
                required
            />
            <AddressAutofill accessToken={mapboxAccessToken} onRetrieve={handleAutofillRetrieval}>
                <input
                    type="text"
                    name="address"
                    placeholder="Address or location"
                    onChange={(e) => setAddress(e.target.value)}
                    autoComplete="address-line1"
                    required
                />
            </AddressAutofill>
            <input
                type="text"
                name="locality"
                placeholder="City"
                value={locality}
                onChange={(e) => setLocality(e.target.value)}
                autoComplete="address-level2"
                required
            />
            <input
                type="text"
                name="state"
                placeholder="State"
                value={state}
                onChange={(e) => setState(e.target.value)}
                autoComplete="address-level1"
                required
            />
            <input
                type="text"
                name="postalCode"
                placeholder="Postcode"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
                autoComplete="postal-code"
                required
            />
            <div>
                <label>Business Hours:</label>
                {businessHours.map((hours, index) => (
                    <div key={index}>
                        <select
                            name="day_of_week"
                            value={hours.day_of_week}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        >
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                        </select>
                        <label> from </label>
                        <input
                            type="time"
                            name="open_time"
                            value={hours.open_time}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        />
                        <label> to </label>
                        <input
                            type="time"
                            name="close_time"
                            value={hours.close_time}
                            onChange={(e) => {
                                handleHoursChange(e, index);
                            }}
                        />
                    </div>
                ))}
                <button onClick={addHours}>Add day</button>
            </div>
            <button type="submit">Create market</button>
        </form>
    );
}
