import type { FeatureCollection } from "geojson";
import { useRef } from "react";
import type { GeoJSONSource, MapLayerMouseEvent, MapRef } from "react-map-gl";
import Map, { Layer, Popup, Source } from "react-map-gl";

import type { Market, ViewState } from "../models/Market";
import { convertTimeStringToLocal } from "../models/Market";
import GeocoderSearchBar from "./geocoderSearchBar";

import "mapbox-gl/dist/mapbox-gl.css";

const mapboxAccessToken =
    "pk.eyJ1IjoianVsaWVua2IiLCJhIjoiY2xrb2s1bjIwMG1rdzNxcHJwMDJvaDUxOSJ9.4k21H-PHzAT7qKOKNWJLbA";
const MAX_ZOOM = 15;
const MIN_ZOOM = 5;

type mapProps = {
    markets: Market[];
    viewState: ViewState;
    focusedMarket: Market | null;
    setViewState: (viewState: ViewState) => void;
    setMarkets: (markets: Market[]) => void;
    setFocusedMarket: (market: Market | null) => void;
};

function MarketMap(props: mapProps) {
    const { markets, viewState, focusedMarket, setViewState, setFocusedMarket } = props;
    const mapRef = useRef<MapRef>(null);

    const loadMarketIcon = () => {
        const map = mapRef.current!.getMap();
        let shopIcon = new Image(32, 32);
        shopIcon.src = "shop.svg";
        shopIcon.onload = () => {
            map.addImage("shop-icon", shopIcon);
        };
    };

    const onClick = (event: MapLayerMouseEvent) => {
        // Only needed to prevent typescript errors about possibly null mapRef
        if (!mapRef.current) {
            return;
        }

        const features = event.target.queryRenderedFeatures(event.point, {
            layers: ["markets", "clustered-markets"],
        });
        if (features.length === 0) {
            console.log("No features found on click");
            setFocusedMarket(null);
            return;
        }

        const feature = features[0];
        if (feature.layer.id === "clustered-markets") {
            // Clicked on a cluster. Zoom in to the cluster until it expands.
            const clusterId = feature.properties?.cluster_id;
            const mapboxSource = mapRef.current.getSource("markets") as GeoJSONSource;

            mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom: number) => {
                if (err) {
                    return;
                }

                mapRef.current?.easeTo({
                    center: (feature.geometry as any).coordinates,
                    zoom,
                    duration: 500,
                });
            });
        }

        if (feature.layer.id === "markets") {
            // Clicked on a specific market. Display the pop-up for that market.
            const marketId = feature.properties?.id;
            const clickedMarket = markets.find((market) => market.id === marketId);
            if (clickedMarket) {
                setFocusedMarket(clickedMarket);
            }
        } else {
            setFocusedMarket(null);
        }
    };

    const marketsFeature: FeatureCollection = {
        type: "FeatureCollection",
        features: markets.map((market) => ({
            type: "Feature",
            geometry: {
                type: "Point",
                coordinates: [market.location.longitude, market.location.latitude],
            },
            properties: {
                id: market.id,
                title: market.name,
                description: market.description,
            },
        })),
    };

    return (
        <Map
            id="marketMap"
            {...viewState}
            ref={mapRef}
            mapStyle="mapbox://styles/mapbox/streets-v11"
            mapboxAccessToken={mapboxAccessToken}
            reuseMaps
            minZoom={MIN_ZOOM}
            maxZoom={MAX_ZOOM}
            onLoad={loadMarketIcon}
            onMove={(evt) => {
                setViewState(evt.viewState);
            }}
            onRender={(event) => event.target.resize()}
            onClick={onClick}
            interactiveLayerIds={["markets"]}
        >
            <GeocoderSearchBar
                mapboxAccessToken={mapboxAccessToken}
                position="top-left"
                setMarkets={props.setMarkets}
            />
            <Source
                id="markets"
                type="geojson"
                data={marketsFeature}
                cluster={true}
                clusterRadius={100}
                clusterMaxZoom={MAX_ZOOM - 1}
            >
                <Layer
                    id="markets"
                    type="symbol"
                    filter={["!", ["has", "point_count"]]}
                    layout={{
                        "icon-image": "shop-icon",
                        "text-field": ["get", "title"],
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Regular"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                        "icon-allow-overlap": true,
                        "text-allow-overlap": true,
                    }}
                />
                <Layer
                    id="clustered-markets"
                    type="symbol"
                    filter={["has", "point_count"]}
                    layout={{
                        "icon-image": "shop-icon",
                        "text-field": "{point_count} markets",
                        "text-font": ["Open Sans Semibold", "Arial Unicode MS Regular"],
                        "text-offset": [0, 1.25],
                        "text-anchor": "top",
                    }}
                />
            </Source>
            {focusedMarket && (
                <Popup
                    latitude={focusedMarket.location.latitude}
                    longitude={focusedMarket.location.longitude}
                    onClose={() => setFocusedMarket(null)}
                    closeOnClick={false}
                    offset={18}
                    className="marketPopup"
                >
                    <div>
                        <h3>{focusedMarket.name}</h3>
                        <p>
                            {focusedMarket.address.street_address}, {focusedMarket.address.locality}
                        </p>
                        {focusedMarket.business_hours.map((hours) => (
                            <p key={hours.day_of_week}>
                                {hours.day_of_week} {convertTimeStringToLocal(hours.open_time)} to{" "}
                                {convertTimeStringToLocal(hours.close_time)}
                            </p>
                        ))}
                    </div>
                </Popup>
            )}
        </Map>
    );
}

export default MarketMap;
